import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import YoutubeEmbed from "../../JS/YoutubeEmbed";

import { useParams } from 'react-router-dom';


export default function EpisodeInfo () {



    const [proudctDetails, setproudctDetails] = useState(null);
    let params = useParams();
    console.log(params.id);
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_series_info&series_id=${id}`)
  
  const allData= data
  setproudctDetails (allData);
  console.log(allData);
  
  }
  
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
  const moiveLink= `http://mhiptv.com:8080/series/skl85/53770547/${params.id}.mkv`
const id = params.id+"mkv"

console.log(moiveLink);

  return  <>
   
   <div className="container d-flex  ">


      <div className="row mt-3">

      <video
            src={moiveLink} 
            width='1440'
            height='680'
            controls
            
            muted
            playsInline
        />

     <button className='btn btn-btn-outline-success'> <a className='fa' href={moiveLink}   f target='_blank' rel='noopener noreferrer'  > download </a></button>  
    


  </div>


  </div>
    </>
};
