import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom"
import Loding from './../Loding/Loding';
export default function TvSeries(){
function getTvSeries(){

    return axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_series_categories`)
   .then((response) =>response).catch((err) =>err)
}
    async function setTvSeries(){
            let response=await getTvSeries();
            setTvSeriesDitails(response)
            console.log(response);
            console.log();
    };
    
    useEffect (()=>{
    setTvSeries();},[]);
    const [TvSeriesDitails, setTvSeriesDitails] = useState(null);
    return<> 

{  TvSeriesDitails !== null  ? <div className="containar col-md-12 d-flex justify-content-center flex-wrap  p-2">
    {TvSeriesDitails?.data?.map((TvSeriesDitails, index) => (
        <div key={index} className="row col-md-3 p-3">
         <Link to={`/TvSeriesDetails/${TvSeriesDitails.category_id}`} >

       <Card>
        <Card.Header className="text-center">{TvSeriesDitails?.category_name}</Card.Header>
       </Card>
       </Link>

       </div>
    ))}
    </div>: <Loding/>
}
</>
}