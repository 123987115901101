import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player'

// Render a YouTube video player
 
function Home (){
  const [movies, setMovies]= useState([])
/*
async function getData(){  
  let {data} = await axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_vod_streams`)
    let {data} = await axios.get(`  http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_live_streams`)
                              
  setMovies(data)
  console.log(data);
  
}
useEffect(()=>{
  getData()

},[])
*/


return<>
<div className=" m-auto mt-5">
<div className="container col-md-10   mt-5   d-flex  justify-content-center align-content-center ">

<div className="row  col-md-3 p-3 m-2 card      ">
<a href="Movies "> <h1 className=' fa text-center m-auto  '  >    Movies      </h1> </a> 
</div>
<div className="row col-md-3 p-3 m-2 card  bg-info   ">  
<a href="TvSeries"><h1 className=' fa text-center m-auto  '  >   Tv Series      </h1> </a> 
</div>
<div className="row col-md-3 p-3 m-2 card  bg-danger   ">  
<a href="LiveTv"> <h1 className=' fa text-center m-auto  '  >   Live Tv      </h1> </a>
</div>

</div>



</div>
</>

};

export default Home;
