import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import YoutubeEmbed from "../../JS/YoutubeEmbed";
import { Carousel } from "flowbite-react"
import { useParams } from 'react-router-dom';


export default function TvSeriesInfo() {



    const [proudctDetails, setproudctDetails] = useState(null);
    const [espose, setespose] = useState(null);


    let params = useParams();
    console.log(params.id);
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_series_info&series_id=${id}`)
   

  const allData= data
  setproudctDetails (allData);
  console.log(allData);

  for (let i = 0; i < 12; i++) {
    setespose(i)
    console.log(i);
    

  }

console.log(proudctDetails);


  }

  
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
  const moiveLink= `http://mhiptv.com:8080/movie/skl85/53770547/${params.id}.mkv`
const id = params.id+"mkv"


  return  <>
   
   <div className="container d-flex  ">
  <div className="row col-md-5 justify-content-center align-content-center card m-auto  ">
        <img className='card-img' src={proudctDetails?.info?.cover} alt="..." />

        <div className="card-body"> 
          <h5 className="card-title">{proudctDetails?.info?.name}</h5>
  
          <p className='card-text'>{proudctDetails?.info?.plot}</p>
          
        </div>
      </div>

      <div className="row  col-md-5 App">

      <video
            src={moiveLink} 
            width='1440'
            height='680'
            controls
            
            muted
            playsInline
        />

     <button className='btn btn-btn-outline-success'> <a className='fa' href={moiveLink}   f target='_blank' rel='noopener noreferrer'  > download </a></button>  
      <h1> youtube trailer</h1>
      <div className="row ">
      <YoutubeEmbed embedId={proudctDetails?.youtube_trailer}    />
      </div>
    </div>
  </div>
  <div className="container">
  <div className="row">
    {proudctDetails?.seasons?.map((movie)=>{
      return <div key={Math.random()} className="col-md-3 p-3 card border-5">
        <Link to={`/EpisodeInfo /${movie?.id}`} >
        <img src={movie.cover} alt="..." />
        <div className="card-body">
          <h5 className="card-title">{movie.name}</h5>
          <h6 className='card-footer'>{        movie?.id          }</h6>
        </div>
        </Link>

      </div>
    })}  
     
  </div>
  <div className="row">
    {proudctDetails?.episodes[1]?.map((movie)=>{
      return <div key={Math.random()} className="col-md-3 p-3 card border-5">
        
        <Link to={`/EpisodeInfo/${movie?.id}`} >
        
        <div className="card-body">
          <img className='card-img' src={proudctDetails?.info?.cover} alt="" />
          <h6 className='card-footer'>{        movie?.title          }</h6>
        </div>
        </Link>

      </div>
    })}  
     
  </div>

  </div>
    </>
};
