import logo from './logo.svg';
 import{Navigate, RouterProvider, createBrowserRouter} from "react-router-dom";
 import Layout from './Components/Layout/Layout';
import Home from './Components/Home/Home';
import NotFound from "./Components/NotFound/NotFound";
import MainSlider from "./Components/MainSlider/MainSlider"
import Footer from "./Components/Footer/Footer";
import TvSeries from "./Components/TvSeries/TvSeries";
import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import  { Toaster } from 'react-hot-toast';
import CategoryDetails from './Components/CategoryDetails/CategoryDetails';
import MoviesDetailes from './Components/MoviesDetailes/MoviesDetailes';
import TvSeriesDetails from './Components/TvSeriesDetails/TvSeriesDetails';
import TvSeriesInfo from './Components/TvSeriesInfo/TvSeriesinfo';
import EpisodeInfo from './Components/EpisodeInfo/EpisodeInfo ';
import LiveTv from './Components/LiveTv/LiveTv';
import LiveTvDetailes from './Components/LiveTvDetailes/LiveTvDetailes ';
import Movies from './Components/Movies/Movies';
import Talwin from './Components/talwin/Talwin';
import AllMovies from './Components/AllMovies/AllMovies';
import Pagination from './Components/Pagination/Pagination';
import AllSerise from './Components/AllSerise/AllSerise';



function App() { 
  
  
  
 
  
  useEffect (()=>{
if(localStorage.getItem("userToken")!==null){
  saveUserData();
}
},[])

  const [userDate, setuUserDate] = useState(null);



   function saveUserData(){
    let encodedToken=  localStorage.getItem("userToken");
    let decodedToken=  jwtDecode(encodedToken);
    setuUserDate(decodedToken);
    

  };
 


let routers = createBrowserRouter ([
    { path:'', element:<Layout  userDate={userDate} setuUserDate={setuUserDate}   />, children:[
    {index:true, element: <Home/> },

    {path:"Footer",element:<Footer/>},
    {path:"Movies",element:<Movies/>},
    {path:"TvSeries",element:<TvSeries/>},
    {path:"LiveTv",element:<LiveTv/>},
    {path:"AllSerise",element:<AllSerise/>},

    
    {path:"Pagination",element:<Pagination/>},

    {path:`talwin`, element :  <Talwin/>    },
    
    {path:`AllMovies`, element :  <AllMovies/>    },


    {path:`CategoryDetails/:id`, element :  <CategoryDetails/>    },
    {path:`MoviesDetailes/:id`, element :  <MoviesDetailes/>    },
    {path:`TvSeriesDetails/:id`, element :  <TvSeriesDetails/>    },
    {path:`LiveTvDetailes/:id`, element :  <LiveTvDetailes/>    },

    {path:`TvSeTalwinriesInfo/:id`, element :  <TvSeriesInfo/>    },
    {path:`EpisodeInfo/:id`, element :  <EpisodeInfo/>    },

     
    

    {path:"MainSlider",element:  <MainSlider/>},
    {path:"*" ,element:<NotFound/>},

  ]}
  
])


return <>


  <Toaster/>

<RouterProvider router={routers} > 
<Toaster/>

</RouterProvider>


</>
}


export default App;
