import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loding from '../Loding/Loding';
import Pagination from 'react-bootstrap/Pagination';
import { useQuery } from 'react-query';

export default function AllSerise() {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const SerisePerPage = 20;

    function getSerise() {
        return axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_series_streams`)
            .then((response) => response)
            .catch((err) => err);
    }

    let { isLoading, data, isError } = useQuery("AllSerise", getSerise);
    console.log(data);
    

    // Ensure data is available and is an array
    const Serise = Array.isArray(data?.data) ? data.data : [];

    // Filter Serise based on search query
    const filteredSerise = Serise.filter((movie) =>
        movie.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Calculate the index range for the current page
    const indexOfLastMovie = currentPage * SerisePerPage;
    const indexOfFirstMovie = indexOfLastMovie - SerisePerPage;
    const currentSerise = filteredSerise.slice(indexOfFirstMovie, indexOfFirstMovie + SerisePerPage);

    // Total number of pages
    const totalPages = Math.ceil(filteredSerise.length / SerisePerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="container-fluid">
                {/* Search Input */}
                <div className="row justify-content-center my-3">
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search for a movie by name..."
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                setCurrentPage(1); // Reset to the first page when searching
                            }}
                        />
                    </div>
                </div>

                {filteredSerise.length > 0 ? (
                    <>
                        <div className="container-fluid col-md-12 d-flex justify-content-center flex-wrap p-2">
                            <div className="row">
                                {currentSerise.map((movie) => (
                                    <div key={movie.stream_id} className="col-md-3 p-3 card border-5">
                                        <Link to={`/SeriseDetailes/${movie?.stream_id}`}>
                                            <img src={movie.stream_icon} alt={movie.name} />
                                            <div className="card-body">
                                                <h5 className="card-title">{movie.name}</h5>
                                                <h6 className='card-footer'>{movie.category_id}</h6>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Pagination */}
                        <Pagination className="justify-content-center">
                            <Pagination.Prev
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            />
                            <Pagination.Item active>
                                {currentPage}
                            </Pagination.Item>
                            <Pagination.Next
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            />
                        </Pagination>
                    </>
                ) : isLoading ? (
                    <Loding />
                ) : (
                    <div>No Serise found</div>
                )}
            </div>
        </>
    );
}
