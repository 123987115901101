import styles from './Navbar.module.css';
import { ReactComponent as YourSvg } from "../../freshcart-logo.svg"
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { IconBase, icons } from 'react-icons';
export default function Navbar({}) {
  

  return <>
  


  
    <nav className=" navbar navbar-expand-lg bg-body-tertiary container-fluid p-3 m-auto    bg-light">
<div className="container-fluid">

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>


    <div class="collapse navbar-collapse" id="navbarSupportedContent">

        
      <ul className="navbar-nav   ">


        <li className='nav-item active w-100  '>
        </li >
        <li className='  m-1'> 
        </li>
        <li className="nav-item active">  
          <Link className="nav-link" to="">Home</Link>
        </li>
        
        <li className="nav-item">
        <Link className="nav-link" to="Movies">Movies </Link>
        </li>
        <li  className="nav-item  ">
        <Link className="nav-link" to="TvSeries"> TvSeries </Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="LiveTv">LiveTv</Link>
        </li>

      
        </ul>

        <ul className="navbar-nav ms-auto m-2   ">
          <li className='d-flex  align-items-center me-3' >
          <a target="_blank" href="https:Fb.com/7atem.a7med"><i className="fa-brands fa-facebook-f fa-2xl m-3"></i> </a>   
      <a target="_blank"  href="https://www.instagram.com/hatemabdelnoor/">  <i className="fa-brands fa-instagram fa-2xl m-3"></i></a>   
        <a target="_blank"  href="https://github.com/HatemAbdelnoor">  <i className="fa-brands fa-github fa-2xl m-3"></i></a>   
          <a target="_blank"  href="https://www.linkedin.com/in/hatem-abdelnoor-28ab04156/"> <i className="fa-brands fa-linkedin fa-2xl m-3"></i></a>   
            </li>

    
    </ul>
  
    </div>
    </div>
  </nav>
   
  </>

 };