import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom"
import Loding from '../Loding/Loding';
export default function LiveTv(){
function getLiveTv(){

    return axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_live_categories`)
   .then((response) =>response).catch((err) =>err)
}
    async function setLiveTv(){
            let response=await getLiveTv();
            setLiveTvDitails(response)
            console.log(response);
            console.log();
    };
    
    useEffect (()=>{
    setLiveTv();},[]);
    const [LiveTvDitails, setLiveTvDitails] = useState(null);
    return<> 

{  LiveTvDitails !== null  ? <div className="containar col-md-12 d-flex justify-content-center flex-wrap  p-2">
    {LiveTvDitails?.data?.map((LiveTvDitails, index) => (
        <div key={index} className="row col-md-3 p-3">
         <Link to={`/LiveTvDetailes/${LiveTvDitails.category_id}`} >

       <Card>
        <Card.Header className="text-center">{LiveTvDitails?.category_name}</Card.Header>
       </Card>
       </Link>

       </div>
    ))}
    </div>: <Loding/>
}
</>
}