import React, { useContext, useState } from "react";
import styles from "./Movies.module.css";
import { useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom"
import Loding from '../Loding/Loding';
import { useQuery } from "react-query";
export default function Movies(){
function getMovies(){

    return axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_vod_categories`)
   .then((response) =>response).catch((err) =>err)
}
    async function setMovies(){
            let response=await getMovies();
            setMoviesDitails(response)

    };
    let {isLoading,data,isError}= useQuery("Movies",getMovies)
console.log(data);

    

    const [MoviesDitails, setMoviesDitails] = useState(null);
    return<> 

{data !== null  ? <div className="containar col-md-12 d-flex justify-content-center flex-wrap  p-2">
    {data?.data?.map((MoviesDitails, index) => (
        <div key={index} className="row col-md-3 p-3">
         <Link to={`/CategoryDetails/${MoviesDitails.category_id}`} >

       <Card>
        <Card.Header className="text-center">{MoviesDitails?.category_name}</Card.Header>
       </Card>
       </Link>

       </div>
    ))}
    </div>: <Loding/>
}
</>
}