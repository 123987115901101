import React from 'react'

export default function Pagination(totalPosts, postsPerPage) {


    let pages =[];
    for (let i = 1 ; i <=  Math.ceil(totalPosts/postsPerPage); i++) {

        pages.push(i); 
    
    
    }  console.log(pages);
    
          return (
    <div>
      {pages.map((page,index) => { 
        return  <button className=' btn btn-dark' key={index}>

            {page} </button>
            
    
      })
      }
    </div>
  )
}
