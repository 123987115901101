import React, { useContext, useEffect, useState } from 'react'
import axios  from 'axios';
import { Link } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Slider from 'react-slick';
import PropTypes from "prop-types";
import "./MoviesDetailes.style.css";
import YoutubeEmbed from "../../JS/YoutubeEmbed";

import { useParams } from 'react-router-dom';


export default function MoviesDetailes() {



    const [proudctDetails, setproudctDetails] = useState(null);
    let params = useParams();
    console.log(params.id);
  async function getproudctdetails(id) { 
  
  let {data}= await axios.get(`http://mhiptv.com:8080/player_api.php?password=53770547&username=skl85&action=get_vod_info&vod_id=${id}`)
  
  const allData= data?.info
  setproudctDetails (allData);
  console.log(allData);
  
  }
  useEffect  (()=>{
  getproudctdetails(params.id);
  },[])
  
  const moiveLink= `http://mhiptv.com:8080/movie/skl85/53770547/${params.id}.mkv`
const id = params.id+"mkv"


  return  <>
   
   <div className="container d-flex  ">
  <div className="row col-md-5 justify-content-center align-content-center card m-auto  ">
        <img className='card-img' src={proudctDetails?.movie_image} alt="..." />

        <div className="card-body"> 
          <h5 className="card-title">{proudctDetails?.name}</h5>
  
          <p className='card-text'>{proudctDetails?.description}</p>
          
          <h6 className='card-footer'>{proudctDetails?.category_id }</h6>
        </div>
      </div>

      <div className="row  col-md-5 App">

      <video
            src={moiveLink} 
            width='1440'
            height='680'
            controls
            
            muted
            playsInline
        />

     <button className='btn btn-btn-outline-success'> <a className='fa' href={moiveLink}   download={proudctDetails?.name} f target='_blank' rel='noopener noreferrer'  > download </a></button>  
      <h1> youtube trailer</h1>
      <div className="row ">
      <YoutubeEmbed embedId={proudctDetails?.youtube_trailer}    />
      </div>
    </div>
  </div>
    </>
};
